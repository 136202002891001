@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}


select {
  width: 100%;
}

input[type='text']:focus,
input[type='email']:focus,
select:focus {
  outline: 2px solid #F9D37F;
}
  

@font-face {
  font-family: 'GT Pressura Standard';
  src:
      url('../public/assets/fonts/GT-Pressura/GT-Pressura-Standard-Regular.woff')
          format('woff'),
          url('../public/assets/fonts/GT-Pressura/GT-Pressura-Standard-Regular.ttf')
          format('truetype'); 
  font-weight: regular;
}

@font-face {
  font-family: 'GT Pressura Standard';
  src:
      url('../public/assets/fonts/GT-Pressura/GT-Pressura-Standard-Bold.woff') format('woff'),
          url('../public/assets/fonts/GT-Pressura/GT-Pressura-Standard-Bold.ttf')
          format('truetype'); 
  font-weight: bold;
} 
