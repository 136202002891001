.settings-page{
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
}
.settings-container {
    margin: 3rem auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    background-color: #f9f9f9;
    max-width: 1081px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) 
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  }

  .section-header {
    max-width: 1038px;
    height: 51px;
    background: #F9D37F;
    align-items: center;
    display: flex;
    padding-left: 1rem;
    font-weight: bold;
    }

.setting_display {
    width: 50%;
    padding-left: 2rem;
  }

.title {
  width: 50%;
  text-align: right;
  padding-right: 2rem;
  font-weight: 600;
}

.subtitle{
  background:#DCDCDC;
  height:3rem;
  display: flex;
  align-items: center;
  justify-content: end;
}

.setting_field{
   width: 100%;
   display: flex;
}

.section-contents{  
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
